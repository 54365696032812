/* style.css */

body {
    background-color: #e3f0f4; /* Your desired background color */
}
.App {
    padding: 0 100px; /* 100 pixels padding on the left and right */
    background-color: #e3f0f4;
  }
  
  .form-container {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      margin-bottom: 20px;
  }
  
  .description-section {
      flex: 1;
      min-width: 200px; /* Adjust minimum width as needed */
  }
  
  .form-section {
      width: 80%;
      padding-left:10%
  }

  .large-section {
    border-radius: 5px;
    background-color: white;
    padding: 15px;
    border: 1px solid #002335;
    margin: 10px;
  }
  
  label {
      display: block;
      margin-bottom: 10px;
  }
  
  input, select {
      width: 100%;
      padding: 8px;
      margin-bottom: 10px;
      border: 1px solid #ccc;
      border-radius: 4px;
  }
  
  h2, h3 {
      color: #333;
  }
  
  pre {
      background-color: #f4f4f4;
      padding: 15px;
      border-radius: 5px;
  }
  
  /* Navigation styles */
  nav {
      background-color: #f8f9fa; /* light grey background */
      padding: 10px 20px;
      border-radius: 5px;
      border: 1px solid #002335;
      box-shadow: 0 2px 4px rgba(0,0,0,0.1);
      display: flex;
      justify-content: center;
      gap: 10px;
      margin: 10px;
      margin-top: 30px;
  }
  
  nav a, button.logout-button {
      padding: 10px 15px;
      text-decoration: none;
      color: white;
      border: 1px solid transparent;
      border-radius: 4px;
      transition: background-color 0.3s, border-color 0.3s;
      background-color: #007bff; /* Primary color for nav links */
  }
  
  nav a:hover, button.logout-button:hover {
      background-color: #0056b3; /* Darker shade on hover */
      border-color: #0056b3;
  }
  
  nav a.active {
      background-color: #0056b3; /* Active link color */
  }
  
  /* Responsive design for smaller screens */
  @media (max-width: 600px) {
    body {
        font-size: 14px; /* Smaller text size for mobile */
    }

    .App {
        padding: 0 20px; /* Reduced padding for mobile */
    }

    .form-container {
        gap: 1px; /* Smaller gap for mobile */
        margin-bottom: 1px; /* Smaller margin-bottom for mobile */
    }

    .form-container select {
        font-size: 16px; /* Adjust font size for mobile devices if needed */
        margin-bottom: 4px;
        margin-top: 2px;
    }

    .large-section {
        padding: 10px; /* Smaller padding for mobile */
        margin: 5px; /* Smaller margin for mobile */
    }

    .form-section {
        padding-left: 5%; /* Smaller padding-left for mobile */
        margin-top: 4px;
    }

    nav {
        padding: 5px 10px; /* Adjusted padding for nav */
    }

    nav a, button.logout-button {
        padding: 8px 10px; /* Smaller padding for nav items */
    }

    /* Adjust other elements as needed */
}
  
  /* Specific style for logout button */
  button.logout-button {
      background-color: #f44336; /* Red background for logout */
  }
  
  button.logout-button:hover {
      background-color: #d32f2f; /* Darker red on hover */
  }
  
  /* Footer Styles */
.footer {
    display: flex;
    justify-content: center; /* Center content horizontally */
    align-items: center;     /* Center content vertically */
    height: 300px;           /* Set the height of the footer */
    margin-top: 20px;
    /* Add other footer styling as needed */
}

.footer-logo {
    max-width: 300px; /* Limit the size of the logo */
    /* Add other logo styling as needed */
}
